
const colors = {
  background: '#0D013E',
  purple: '#0C0B61',
  white: '#ffffff'
};

export const theme = {
  colors,
};
